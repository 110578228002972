import React from 'react';
import { css } from '@emotion/react';
import { color } from '../components/defaults/styles/theme';
import mq from '../components/defaults/styles/mediaquerys';
import { wrapper } from '../components/defaults/styles/elements';

import { styleInfoFrame, Profile__Info_Top, Profile__Horizontal, ListTitelEl, styleInfoEl, Profile__Main_List_EL, Profile__Default, Profile__Type_Inline, Profile__Programs, Profile__Website, Profile__Social_Card, Profile__Tags } from './profile';

const ListProfileStartup = ({data}) => {

  return (
    <div
      css={[
        styleInfoEl,
        css`
          background: white;
          width: 100%;
          border-top: none;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          padding: 0 1.5em 2.5em;
          position: relative;
          ${mq[2]} {
            padding: 0 2.5em 2.5em;
          }
        `,
      ]}
    >
      <Profile__Main_List_EL title="Startup Name" content={data.startup} />
      <Profile__Default
        title="Headquarters"
        content={data.city ? data.country + ", " + data.city : data.country}
      />
      { data.founded ? (
        <Profile__Default
          title="Founded"
          content={
            data.founded + " (about " + (new Date().getFullYear() - data.founded) + " years ago)"
          }
        />
      ) : ``}

     {data.number_employees ? (
        <Profile__Default
        title="No. of Employees"
        content={data.number_employees}
      />
     ) : ``}
      
      {data.stage ? (
        <div
          css={css`
            padding: 1em 0;
          `}
        >
          <ListTitelEl>Investment Stage</ListTitelEl>
          <div
            css={css`
              line-height: 0;
              margin-top: 0.5em;
            `}
          >
            <Profile__Tags content={data.stage} />
          </div>
        </div>
      ) : ``}

      <Profile__Type_Inline
        css={css`
          padding: 1em 0;
          ${mq[2]} {
            display: none;
          }
        `}
        type={data.vertical}
        title="Startup Vertical"
        startup
      />
      <Profile__Programs program_string={data.startup_program} />
      <Profile__Website url={data.url} />
      <Profile__Social_Card
        twitter={data.twitter}
        linkedin={data.linkedin}
        instagram={data.instagram}
      />
    </div>
  )
}

const StartupCharacteristics = ({ data, id, svg }) => {

  return (
    <>
      <section
        css={css`
            background: ${color.main_dark};
            width: 100%;
            min-height: 25rem;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: ${color.accent_light};
            }
        `}
      >
        <div css={[wrapper, styleInfoFrame, css``]}>
          <Profile__Info_Top id={id} />
        </div>

        <div
          css={css`
              height: 5rem;
              width: 100%;
              background: white;
            `}
        >
          <Profile__Horizontal
            type={data.vertical}
            twitter={data.twitter}
            linkedin={data.linkedin}
            instagram={data.instagram}
            startup
          />
        </div>
      </section>

      <div
        css={css`
            background: ${color.main_light};
            width: 100%;
          `}
      >
        <div
          css={[
            wrapper,
            styleInfoFrame,
            css`
                flex-direction: column;
                ${mq[2]} {
                  flex-direction: row;
                }
              `,
          ]}
        >
          <ListProfileStartup data={data} id={id} />
          {data.description ? (
            <div
              css={css`
                max-width: 45rem;
                padding: 3rem 0 0;
                align-self: flex-start;
                ${mq[2]} {
                  padding-left: 3rem;
                  padding-right: 0;
                  padding-top: 3rem;
                }
              `}
            >
              {" "}
              <ListTitelEl>Plug and Play‘s Overview of {data.startup}</ListTitelEl>
              <div
                css={css`
                  p {
                    margin: 0;
                    padding-bottom: 1em;
                    font-size: 0.85em;
                    line-height: 1.8em;
                    ${mq[1]} {
                      font-size: 0.95em;
                    }
                  }c
                `}
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              ></div>
            </div>
          ) : ''}
        </div>
      </div>
    </>
  )
};

export default StartupCharacteristics;