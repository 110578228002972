import React from 'react';
import { css } from '@emotion/react';
import { color } from './defaults/styles/theme';
import BackgroundImage from 'gatsby-background-image-es5';
import Mail from './defaults/assets/svg/mail.svg';
import Phone from './defaults/assets/svg/phone.svg';
import { People } from './defaults/assets/svg/mobilenav';
import { IconButton } from './defaults/styles/elements';
import mq from './defaults/styles/mediaquerys';

const ButtonStyle = css`
  z-index: 1;
  position: relative;
  display: inline-flex;
  align-items: center;
  color: white;
  &:before {
    background: ${color.main_dark};
  }
  :not(:last-of-type) {
    margin-bottom: .5em;
  }
  svg {
    stroke: white;
  }
`

const ContactCard = ({ person, className }) => {
  
  let recordId = person.recordId
  person = person.data

  return (
    <div 
      className={className}
      css={css`
        background: ${color.accent_light};
        border-radius: 5px;
        padding: 1em;
        display: flex;
        align-items: stretch;
        flex: 0 1 100%;
        p {
          margin-bottom: 0;
        }
        ${mq[0]} {
          padding: 2em;
        }
      `}
    >
      <div css={css`
        width: 110px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 2em;
      `}>
        <BackgroundImage fluid={person.image.localFiles[0].childImageSharp.fluid} css={css`
            width: 100%;
            height: 100%;
        `} />
      </div>
      <div css={css`
        padding: 1em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}>
        <div>
          <p css={css`
            font-size: .85rem;
          `}><b>{person.name}</b></p>
          <p css={css`
            font-size: .75em;
          `}>{person.profession}</p>
          { person && person.partner_company && person.partner_company.length > 0 ? (
            <p css={css`
            font-size: .75em;
          `}>{person.partner_company[0].data.name}</p>
          ) : ''}
          {person.phone_number ? <a css={css`
            color: ${color.main_dark};
            margin-top: .75em;
            font-size: .75em;
            display: inline-block;
            text-decoration: none;
            svg {
              height: 1em;
              width: auto;
              margin-right: .5em;
            }
          `} href={"tel:" + person.phone_number}><Phone /> {person.phone_number}</a> : ""}
        </div>

        <div css={css`
          margin-top: 1em;
          * {
            margin-right: 1em;
          }
        `}>
          {person.email && person.mail_public ? (
            <IconButton white name="Send EMail" url={`mailto:${person.email}`} icon={<Mail />} css={ButtonStyle} />
            ) : (
              ""
            )
          }
          <IconButton white name="Profile" url={`/people/${recordId}`} icon={<People />} css={ButtonStyle} />
        </div>
      </div>
    </div>
  )
}

export default ContactCard;