import axios from 'axios';
import { isBrowser ,getUser, setUser } from './auth';


export const getPassphrase = () => {
  let user = getUser()
  if (user && user.secret === process.env.GATSBY_SECRET) {
    return user.passphrase
  } else {
    return null
  }
}

export const getProfile = () => {
  let profile = getUser()?.profile
  if (profile && profile.firstname && profile.lastname && profile.company && profile.email) {
    return profile
  } else {
    return null
  }
}

export const handleProfileSetup = ( profile ) => {
  let user = getUser()
  user["profile"] = profile
  setUser(user)
}

export const register = (recordId, title, limit, handleRegistrationState) => {
  let user = getUser().profile
  let passphrase = getUser().secret
  let type = getUser().ticket_type
  let session = {}

  session["id"] = recordId
  session["title"] = title
  session["limit"] = limit && limit > 0 ? true : false


  sendRegistrationWebhook(passphrase, user, type, session, handleRegistrationState)
  handleRegistrationState("process")
};

export const getBookings = () =>
  isBrowser() && window.localStorage.getItem("EXPObookings")
    ? JSON.parse(window.localStorage.getItem("EXPObookings"))
    : []

const setBookings = (bookings) =>
  window.localStorage.setItem("EXPObookings", JSON.stringify(bookings))

const updateBookings = (recordId, booking_id, error) => {
  let existingBookings = getBookings();
  let isNew = true

  // check whether this session_id already exists in the local storage
  for(var i = 0; i < existingBookings.length; i += 1) {
    if(existingBookings[i]["session_id"] === recordId) {
      if(existingBookings[i]["booking_id"]) {
        return true
      } else if ( existingBookings[i]["error"] && booking_id) {
        existingBookings[i]["error"] = undefined
        existingBookings[i]["booking_id"] = booking_id
      } else if ( existingBookings[i]["error"] && error) {
        existingBookings[i]["error"] = error
      } else {
        existingBookings[i]["booking_id"] = booking_id
      }
      isNew = false;
      break;
    }
  }
  
  if (isNew === true) {
    let newBooking = {}

    newBooking["session_id"] = recordId
    newBooking["booking_id"] = booking_id

    if ( error ) {
      newBooking["error"] = error
    }

    existingBookings.push(newBooking)
  }

  setBookings(existingBookings)
}

export const getBooking = (recordId) => {
  if (isBrowser()) {
    const bookings = getBookings()
    for(var i = 0; i < bookings.length; i += 1) {
      if(bookings[i]["session_id"] === recordId) {
        return bookings[i]
      }
    }
    return false
  }
};


export const getConfirmation = (recordId) => {
  if (isBrowser()) {
    const bookings = getBookings()
    for(var i = 0; i < bookings.length; i += 1) {
      if(bookings[i]["session_id"] === recordId) {
        return bookings[i].booking_id
      }
    }
    return false
  }
};

const sendRegistrationWebhook = (passphrase, user, type, session, handleRegistrationState) => {
  const content = {
    attendee: user,
    type: type,
    session: session
  }

  axios({
    method: 'post',
    url: 'https://n8n.bastiankroggel.com/webhook/83e216ca-5ab6-4fc7-be50-0906c8c81fe0',
    data: content,
    timeout: 20000,
    headers : {
      "content-type": 'application/json',
      "passphrase": passphrase
    }
  }).then(response => {
    let timestamp = response?.data?.timestamp
    let session_id = response?.data?.session_id
    let booking_id = response?.data?.booking_id

    if ( booking_id ) {
      updateBookings(session_id, booking_id)
      handleRegistrationState("registered")
      console.log(`You have been successfully registered for "${session.title}" (id: ${session_id}). Your booking code is ${booking_id} and has been registered on our system at ${timestamp}.`)
    } else {
      handleRegistrationState("failed")
      updateBookings(content.session.id, undefined, "unknown")
    }
  }).catch(error => {
    if (error && error.message && error.message.includes("timeout")) {
      updateBookings(content.session.id, undefined, "timeout")
    } else {
      updateBookings(content.session.id, undefined, "unknown")
    }
    handleRegistrationState("failed")
  })
}