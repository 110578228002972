import React from 'react';
import ReactModal from 'react-modal';
import { Global, css } from '@emotion/react';
import { Formik, Field, Form } from 'formik';
import { IconButtonBtn, TitleHeader } from '../components/defaults/styles/elements';
import User from '../components/defaults/assets/svg/user.svg';
import Send from '../components/defaults/assets/svg/send.svg';
import { handleProfileSetup, register } from '../services/registration';
import { color } from '../components/defaults/styles/theme';



const ProfileForm = ({openRegistration, handleRegistrationState, recordId, title, limit}) => {

  const formField = css`
    display: flex;
    flex-direction: column;
    &:not(:last-of-type) {
      margin-bottom: .75em;
    }
    label {
      p {
        font-size: .65em;
        font-weight: 600;
        padding: .25em .8em;
        letter-spacing: .02em;
        background: ${color.main_light};
        display: inline-block;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        margin-bottom: 0;
        margin-left: .25em;
      }
    }
    input {
      border-radius: 5px;
      border: none;
      font-family: monospace;
      background: ${color.accent_light};
      font-size: 1em;
      height: 3.5em;
      outline: none;
      font-weight: 500;
      width: 100%;
      padding: 1em;
    }
  `

  const errorMsg = css`
    margin: .2em .2em 0;
    position: relative;
    p {
      margin-bottom: 0;
      font-size: .7em;
      padding: .35em;
      color: ${color.red};
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background: ${color.red};
      z-index: -1;
      opacity: .2;
    }
  `

  function validateRequired(value) {
    let error;
    if (!value) {
      error = 'This field is required. Please do not leave empty.';
    }
    return error;
  }
  
  function emailValidation(value) {
    let error;
    if (!value) {
      error = 'Your email is required in order to send out the confirmation notification.';
    } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)) {
      error = 'Please make sure to enter a valid email address.'
    }
    return error;
  }
  
  return (
    <div css={css`
      background: white;
      width: 95%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 40rem;
      border-radius: 5px;
      padding: 2em;
      max-height: 90%;
      overflow-y: scroll;
    `}>
      <TitleHeader
        title="Complete your profile"
        subtitle="<p>In order to request a spot for one of the Breakout Sessions 
          we kindly ask you to complete the information form below.</p><p>A confirmation will be send to you via email.</p>"
        icon={<User />}
        css={css`
          padding-top: 0;
        `}
        inline
        size="1.2"
      />
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          company: "",
          email: ""
        }}
        onSubmit={profile => {
          handleProfileSetup( profile )
          register(recordId, title, limit, handleRegistrationState)
          openRegistration()
        }}
      >
        {({ errors, touched, isValidating}) => (
          <Form css={css`
            margin-bottom: 0;
          `}>
            <div css={formField}>
              <label htmlFor="firstname"><p>First Name</p></label>
              <Field id="firstname" validate={validateRequired} name="firstname" />
              {errors.firstname && touched.firstname && <div css={errorMsg}><p>{errors.firstname}</p></div>}
            </div>
            
            <div css={formField}>
              <label htmlFor="lastname"><p>Last Name</p></label>
              <Field id="lastname" validate={validateRequired} name="lastname" />
              {errors.lastname && touched.lastname && <div css={errorMsg}><p>{errors.lastname}</p></div>}
            </div>

            <div css={formField}>
              <label htmlFor="company"><p>Company</p></label>
              <Field id="company" validate={validateRequired} name="company" />
              {errors.company && touched.company && <div css={errorMsg}><p>{errors.company}</p></div>}
            </div>
            
            <div css={formField}>
              <label htmlFor="position"><p>Position/Profession</p></label>
              <Field id="position" validate={validateRequired} name="position" />
              {errors.position && touched.position && <div css={errorMsg}><p>{errors.position}</p></div>}
            </div>
            
            <div css={formField}>
              <label htmlFor="email"><p>Company Email Address</p></label>
              <Field
                id="email"
                validate={emailValidation}
                name="email"
                type="email" />
              {errors.email && touched.email && <div css={errorMsg}><p>{errors.email}</p></div>}
            </div>
            
            <div css={css`
              margin-top: 3em;
              display: flex;
              justify-content: flex-end;
              button:not(:last-of-type) {
                margin-right: 1em;
              }
            `}>
              <IconButtonBtn
                type="button"
                onClick={openRegistration}
                name="Cancel"
                css={css`
                  color: ${color.main_dark};
                  &:before {
                    background: none;
                  }
                `}
              />
              <IconButtonBtn type="submit" name="Submit Form" icon={<Send />} />
            </div>


          </Form>
        )}
      </Formik>
    </div>
  )
}


ReactModal.setAppElement('#___gatsby')
const customStyles = {
  overlay : {
    zIndex: '9999999',
    background: "rgba(0,0,0,0.85)"
  },
};

const RegistrationModal = ({registrationOpen, openRegistration, recordId, title, limit, handleRegistrationState}) => {

  return(
    <>
      <Global styles={css`
        .ReactModal__Overlay {
            opacity: 0;
            transition: opacity 250ms ease-in-out;
        }

        .ReactModal__Overlay--after-open{
            opacity: 1;
        }

        .ReactModal__Overlay--before-close{
            opacity: 0;
        }
      `}/>
      <ReactModal
        isOpen={registrationOpen}
        closeTimeoutMS={250}
        style={customStyles}
        className={"ModalInner"}
      >
        
        <ProfileForm 
          openRegistration={openRegistration}
          recordId={recordId}
          title={title}
          limit={limit}
          handleRegistrationState={handleRegistrationState}
        />
      </ReactModal>
    </>
  )
}


export default RegistrationModal;