import React from "react"
import StartupProfile from "../components/startup"
import Event from "../components/event"
import RearviewPageComponent from '../components/rearview_page_component';
import Layout from "../components/layout"
import { Router } from "@reach/router"
import { graphql, navigate } from "gatsby"
import AllAccessRoute from '../components/allaccessRoute';
import Login from '../components/login';
import { isBrowser } from '../services/auth';
import GeneralFooter from '../components/general_footer';
import { color } from '../components/defaults/styles/theme';
import Flags from '../data/raw/flags';
import recordFilter from '../components/defaults/filters/record';
import rearviewSorting from '../components/defaults/sorting/rearviewSorting';


const AllAccess = ({data, location}) => {
    const startup = data.startups.edges
    const event = data.events.edges
    const rearview = rearviewSorting(data.rearview.edges)


    const NotFound = () => {
      if (!isBrowser()) return false
      
      navigate("/404")
      return null
    }

    return (
      <Layout
        dark={(location.pathname.includes(`/${Flags.settings.protectedArea.url}/startups`) || location.pathname.includes(`/${Flags.settings.protectedArea.url}/rearview`)) ? true : false}
        individualSEO
      >
        <Router>
          <NotFound default />
          {startup.map(({ node }, index) => (
            <AllAccessRoute
              path={`/${Flags.settings.protectedArea.url}/startups/` + node.recordId}
              data={node.data}
              id={node.id}
              recordId={node.recordId}
              key={index}
              component={StartupProfile}
              prevLocation={location.pathname}
            />
          ))}
          {event.map(({ node }, index) => (
            <AllAccessRoute 
              path={`/${Flags.settings.protectedArea.url}/agenda/` + node.recordId}
              data={node.data}
              recordId={node.recordId}
              key={index}
              component={Event}
              prevLocation={location.pathname}
            />
          ))}
          {rearview.map(( node , index) => {
            let context = {}
            context["prev"] = {}
            context["next"] = {}
            context["index"] = index + 1
            context["length"] = rearview.length
            context.prev["password_protected"] = rearview[index - 1]?.data.password_protected
            context.prev["recordId"] = rearview[index - 1]?.recordId
            context.next["password_protected"] = rearview[index + 1]?.data.password_protected
            context.next["recordId"] = rearview[index + 1]?.recordId
            
            if (node.data.password_protected) {
              return (
                <AllAccessRoute 
                  path={`/${Flags.settings.protectedArea.url}/rearview/` + recordFilter(node.recordId)}
                  data={node.data}
                  recordId={node.recordId}
                  key={index}
                  component={RearviewPageComponent}
                  prevLocation={location.pathname}
                  clientContext={context}
                />
              )
            } else return undefined
          })}
          <Login path={`/${Flags.settings.protectedArea.url}/login`} image={data.file} />
        </Router>
        <GeneralFooter theme={location.pathname.includes(`/${Flags.settings.protectedArea.url}/rearview`) ? color.main_dark : color.main_light} />
      </Layout>
    )
}

export const query = graphql`
         {
           startups: allAirtable(
             filter: {
               table: { eq: "startups" }
               data: { include: { eq: true } }
             }
           ) {
             edges {
               node {
                 id
                 recordId
                 data {
                   startup
                   display_company
                   vertical
                   startup_program
                   country
                   city
                   founded
                   number_employees
                   number_pilots
                   stage
                   users {
                     recordId
                     data {
                       include
                       name
                       profession
                       image {
                         localFiles {
                           childImageSharp {
                             fluid {
                               ...GatsbyImageSharpFluid
                             }
                           }
                         }
                       }
                     }
                   }
                   agenda {
                     recordId
                     data {
                       agenda_description {
                         childMarkdownRemark {
                           html
                           excerpt(format: HTML, truncate: true, pruneLength: 512)
                         }
                       }
                       agenda_start_time
                       agenda_publish
                       agenda_tags
                       agenda_title
                       agenda_duration
                       agenda_teaser
                       agenda_partners {
                         recordId
                         data {
                           name
                         }
                       }
                       agenda_startups {
                         recordId
                         data {
                           startup
                         }
                       }
                     }
                   }
                   rearview {
                     recordId
                     data {
                       sort
                       publish
                       password_protected
                       rearview_tags
                       rearview_thumbnail {
                         localFiles {
                           childImageSharp {
                             fluid {
                               ...GatsbyImageSharpFluid_withWebp_tracedSVG
                             }
                           }
                         }
                       }
                       rearview_description {
                         childMarkdownRemark {
                           excerpt(format: PLAIN, pruneLength: 240)
                         }
                       }
                       rearview_release
                       rearview_title
                       rearview_partners {
                         data {
                           name
                         }
                       }
                       rearview_startups {
                         data {
                           startup
                         }
                       }
                       rearview_agenda {
                         data {
                           agenda_start_time
                         }
                       }
                     }
                   }
                   description {
                     childMarkdownRemark {
                       html
                     }
                   }
                   url
                   twitter
                   linkedin
                   casestudy {
                     data {
                        cs_title
                        cs_publish
                        cs_teaser {
                          childMarkdownRemark {
                            html
                          }
                        }
                        cs_file {
                          localFiles {
                            publicURL
                          }
                        }
                        cs_thumbnail {
                          localFiles {
                            childImageSharp {
                              fluid {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                              }
                            }
                          }
                        }
                        cs_agenda {
                          recordId
                          data {
                            agenda_title
                            agenda_start_time
                          }
                        }
                        cs_partner {
                          data {
                            name
                          }
                        }
                        cs_downloadable
                        cs_contact {
                          recordId 
                          data {
                            include
                            name
                            profession
                            partner_company {
                              data {
                                name
                              }
                            }
                            email
                            mail_public
                          }
                        }
                     }
                   }
                 }
                 recordId
               }
             }
           }
           events: allAirtable(
             filter: { 
               table: { eq: "agenda" },
               data: {agenda_publish: {eq: true}}
             }
           ) {
             edges {
               node {
                 id
                 recordId
                 data {
                   agenda_description {
                     childMarkdownRemark {
                       html
                     }
                   }
                   agenda_agenda {
                     childMarkdownRemark {
                       html
                     }
                   }
                   agenda_start_time
                   agenda_tags
                   agenda_attendee_limit
                   breakout_session
                   agenda_registration
                   agenda_title
                   agenda_duration
                   agenda_teaser
                   agenda_startups {
                     recordId
                     data {
                       startup
                     }
                   }
                   agenda_partners {
                     recordId
                     data {
                       name
                     }
                   }
                   agenda_dial_in
                   agenda_host {
                     id
                     recordId
                     data {
                       include
                       name
                       firstname
                       surename
                       profession
                       partner_company {
                         data {
                           name
                         }
                       }
                       startup_company {
                         data {
                           startup
                         }
                       }
                       custom_company
                       image {
                         localFiles {
                           childImageSharp {
                             fluid {
                               ...GatsbyImageSharpFluid_withWebp_tracedSVG
                             }
                           }
                         }
                       }
                     }
                   }
                   agenda_contact {
                     recordId
                     data {
                       include
                       name
                       firstname
                       surename
                       profession
                       email
                       mail_public
                       phone_number
                       partner_company {
                         data {
                           name
                         }
                       }
                       startup_company {
                         data {
                           startup
                         }
                       }
                       custom_company
                       image {
                         localFiles {
                           childImageSharp {
                             fluid {
                               ...GatsbyImageSharpFluid_withWebp_tracedSVG
                             }
                           }
                         }
                       }
                     }
                   }
                   agenda_speaker {
                     id
                     recordId
                     data {
                       include
                       name
                       profession
                       partner_company {
                         data {
                           name
                         }
                       }
                       startup_company {
                         data {
                           startup
                         }
                       }
                       custom_company
                       image {
                         localFiles {
                           childImageSharp {
                             fluid {
                               ...GatsbyImageSharpFluid_withWebp_tracedSVG
                             }
                           }
                         }
                       }
                     }
                   }
                   agenda_casestudies {
                     data {
                       cs_title
                       cs_publish
                       cs_teaser {
                         childMarkdownRemark {
                           html
                         }
                       }
                       cs_startup {
                         recordId
                         data {
                           startup
                           vertical
                           country
                           city
                         }
                       }
                     }
                   }
                   agenda_rearview {
                     recordId
                     data {
                       sort
                       publish
                       password_protected
                       rearview_tags
                       rearview_thumbnail {
                         localFiles {
                           childImageSharp {
                             fluid {
                               ...GatsbyImageSharpFluid_withWebp_tracedSVG
                             }
                           }
                         }
                       }
                       rearview_description {
                         childMarkdownRemark {
                           excerpt(format: PLAIN, pruneLength: 240)
                         }
                       }
                       rearview_release
                       rearview_title
                       rearview_partners {
                         data {
                           name
                         }
                       }
                       rearview_startups {
                         data {
                           startup
                         }
                       }
                       rearview_agenda {
                         data {
                           agenda_start_time
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
           rearview: allAirtable(
             filter: { data: { publish: { eq: true } }, table: { eq: "rearview" } }
             sort: { fields: [data___sort, data___rearview_title], order: ASC }
           ) {
              edges {
                node {
                  recordId
                  data {
                    sort
                    publish
                    rearview_link
                    rearview_tags
                    rearview_title
                    rearview_release
                    password_protected
                    rearview_people {
                      id
                      recordId
                      data {
                        name
                        profession
                        include
                        consent
                        partner_company {
                          data {
                            name
                          }
                        }
                        custom_company
                        startup_company {
                          data {
                            startup
                          }
                        }
                      }
                    }
                    rearview_timestamp
                    rearview_description {
                      childMarkdownRemark {
                        excerpt(format: HTML, pruneLength: 240)
                        html
                      }
                    }
                    rearview_agenda {
                      recordId
                      data {
                        agenda_publish
                        agenda_start_time
                        agenda_title
                      }
                    }
                    rearview_partners {
                      id
                      recordId
                      data {
                        active
                        name
                      }
                    }
                    rearview_startups {
                      id
                      recordId
                      data {
                        include
                        startup
                      }
                    }
                    rearview_thumbnail {
                      localFiles {
                        childImageSharp {
                          fluid(grayscale: true, quality: 60, maxWidth: 900) {
                            src
                          }
                        }
                      }
                    }
                  }
                }
              }
           }
         }
       `

export default AllAccess;